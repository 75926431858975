import React from 'react';
import type { WBabyPublicRegistryView, WCmsZolaRegistryView } from '@zola/svc-web-api-ts-client';

import ZolaRegistry from 'components/publicWebsiteV2/pages/Registry/ZolaRegistry';
import { Container, RegistryContent } from './BabyRegistry.styles';

type RegistryProps = {
  babyRegistry?: WBabyPublicRegistryView;
};

const Registry = ({ babyRegistry }: RegistryProps): JSX.Element => {
  const { object_id, gift_cards_enabled } = babyRegistry || {};

  // Convert to
  const zolaRegistry: WCmsZolaRegistryView = {
    status: undefined,
    active: true,
    greeting: '',
    profile_image: '',
    enable_custom_collections: false,
    registry_object_id: object_id,
    default_gift_card_enabled: gift_cards_enabled,
  };

  return (
    <Container>
      <RegistryContent>
        <ZolaRegistry isGuestViewing zolaRegistry={zolaRegistry} />
      </RegistryContent>
    </Container>
  );
};

export default Registry;
