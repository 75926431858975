import styled from '@emotion/styled';
import {
  ZolaRegistryHeader,
  ZolaRegistryFooter,
} from 'components/publicWebsiteV2/pages/Registry/ZolaRegistry/ZolaRegistry.styles';

import { Container as TitleImgDescContainer } from 'components/publicWebsiteV2/common/TitleImgDesc/TitleImgDesc.styles';

export const Container = styled.div`
  // padding-bottom: 120px;

  ${ZolaRegistryHeader} {
    display: none;
  }

  ${ZolaRegistryFooter} {
    display: none;
  }
  ${TitleImgDescContainer} {
    display: none;
  }
`;

export const RegistryContent = styled.div`
  min-height: 170px;
`;
